<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="14">
        <span style="font-weight: bold">待选题目：</span>
        <el-row class="label-item-left" :gutter="10">
          <!-- 高级搜索条件 -->
          <heightSearch
            ref="heightSearch"
            :sourceList="sourceList"
            :tagTreeData="tagTreeData"
            :difficultyList="difficultyList"
            :knowledgeList="tagTreeData"
            @changCurrengOne="changCurrengOne"
            @getQuestionList="getQuestionList"
          />
        </el-row>

        <el-row class="label-item-left">
          <el-table
            ref="multipleTable"
            :data="dataList"
            border
            row-key="problem_id"
            fit
            highlight-current-row
            class="tb-list"
            @selection-change="handleSeleChange"
          >
            <!--
            -->
            <el-table-column
              reserve-selection
              type="selection"
              width="55"
              align="center"
            ></el-table-column>
            <el-table-column
              label="问题ID"
              align="center"
              width="120"
              prop="problem_id"
            ></el-table-column>
            <el-table-column
              show-overflow-tooltip
              label="问题标题"
              align="left"
              prop="title"
            >
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  underline
                  class="one-line"
                  @click="quesDetail(scope.row)"
                  >{{ scope.row.title }}
                </el-link>
              </template>
            </el-table-column>
            <el-table-column label="标签" align="left" prop="title" width="300">
              <template v-slot="{ row }">
                <el-tag
                  size="mini"
                  style="margin: 0 6px; border: none"
                  v-for="item in row.tagList"
                  :key="item.id"
                  :color="item.groupInfo.color"
                  effect="dark"
                >
                  {{ item.title }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column label="提交记录" align="left" prop="title">
              <template slot-scope="scope">
                <span class="pointer" @click="subRecord(scope.row)"
                  >{{ scope.row.accepted }}/{{ scope.row.submit }}</span
                >
              </template>
            </el-table-column>
          </el-table>
        </el-row>

        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="pagination.pageSizes"
          :page-size="pagination.pageSize"
          :layout="pagination.layout"
          :total="pagination.total"
        >
        </el-pagination>
      </el-col>

      <el-col :span="10">
        <span style="font-weight: bold">已选题目：</span>
        <!-- 搜索id -->
        <!-- <template> -->
        <el-popover
          placement="right"
          width="400"
          :style="{
            'margin-left': '10px',
            position: 'absolute',
            right: '4px',
            top: '0px',
            'z-index': '99',
          }"
          trigger="click"
          ref="popover"
        >
          <el-input
            type="textarea"
            placeholder="4443
4444
4445
4446
……
……
请按每行一个的数字格式填写要添加的题目，点击导入按钮"
            rows="8"
            v-model="batchStr"
            show-word-limit
            @input="batchStr = batchStr.replace(/[^\d\r'\n']/g, '')"
          ></el-input>
          <el-button slot="reference" size="mini" @click="batchStr = ''"
            >批量添加
          </el-button>
          <el-button
            size="mini"
            type="primary"
            style="margin-top: 10px"
            @click="batchAddId"
            >导入
          </el-button>
        </el-popover>
        <el-row class="haschoose">
          <el-table
            ref="dragTable"
            height="500"
            :data="multipleSelection"
            border
            fit
            row-key="problem_id"
            highlight-current-row
            class="tb-list"
          >
            <el-table-column label="题号" align="center">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column
              label="ID"
              align="center"
              prop="problem_id"
            ></el-table-column>
            <el-table-column
              label="标题"
              align="center"
              prop="title"
            ></el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <i
                  class="el-icon-sort"
                  style="font-size: 16px; margin-right: 10px; cursor: pointer"
                ></i>

                <i
                  @click="quesDetail(scope.row)"
                  class="el-icon-view"
                  style="font-size: 16px; margin-right: 10px; cursor: pointer"
                ></i>

                <i
                  class="el-icon-delete-solid"
                  style="font-size: 16px; cursor: pointer"
                  @click="deleteItem(scope.row, scope.$index)"
                ></i>
              </template>
            </el-table-column>
          </el-table>
        </el-row>

        <!-- 不存在的id -->
        <el-card
          class="haschoose"
          shadow="nover"
          style="margin-top: 25px"
          v-if="isNonentity"
          :style="{ height: '500px' }"
        >
          <h3
            style="
              color: gray;
              margin-bottom: 10px;
              border-bottom: 1px solid rgba(16, 31, 28, 0.1);
            "
          >
            不存在的ID({{ seleErrTopic.length }})
          </h3>
          <div style="overflow-y: auto; height: 450px">
            <el-col v-for="(item, index) of seleErrTopic" :key="index">
              <p
                style="
                  margin-bottom: 10px;
                  border-bottom: 1px solid rgba(16, 31, 28, 0.1);
                "
              >
                {{ item }}
              </p>
            </el-col>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Sortable from "sortablejs";
// import { getSimpleQuestionList, listByGeneral } from "@/api/research/question";
import { getTags, getTagGroupList } from "@/api/research/tag";
import getCourseType from "@/mixins/getCourseType";
import heightSearch from "@/components/research/question-manage/heightSearch";

import {
  updateQuestionLibrary,
  deleteContestProblem,
} from "@/api/research/contest";

export default {
  components: {
    Sortable,
    heightSearch,
  },
  mixins: [getCourseType],
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    contest_id: {
      default: "",
      type: String,
    },
    problemList: {
      default: () => {
        return [];
      },
      type: Array,
    },
  },
  watch: {
    show() {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields();
        }
      }
    },
  },
  data() {
    return {
      seleTopic: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
      },
      queryData: {
        inputVal: "",
        select: "1",
        seleTag: "",
        tagId: "",
      },
      tagList: [], // 已启用的标签
      dataList: [],
      newList: [],
      oldList: [],
      multipleSelection: [],
      tagTreeDatafirst: [], // 标签树形结构数据
      tagTreeDatasecond: [], // 标签树形结构数据
      tagTreeDatathird: [], // 标签树形结构数据
      sourceList: [],
      tagTreeData: [], // 标签树形结构数据
      difficultyList: [],
      // 搜索id
      arr: [],
      batchStr: "",
      seleErrTopic: [],
      isNonentity: false,
    };
  },

  mounted() {
    this.getTagGroupList("first");
    this.getTagGroupList("second");
    this.getTagGroupList("third");
    this.seleTopic = [];
    this.problemList.forEach((item) => {
      this.seleTopic.push(item);
    });

    // this.getQuestionList();
    this.$nextTick(() => {
      this.$refs.heightSearch.search();
    });
    this.getTags();

    this.oldList = this.seleTopic.map((v) => v.problem_id);
    this.newList = this.oldList.slice();
    this.$nextTick(() => {
      this.setSort();
    });
  },

  methods: {
    // 批量添加
    async batchAddId() {
      if (!this.batchStr) {
        window.$msg("导入的ID不能为空", 2);
        return false;
      }
      let problemIdSet = this.batchStr.split("\n");
      const res = await getSimpleQuestionList({
        problemIdSet,
      });

      if (res.state === "success") {
        let realSort = [];
        problemIdSet.forEach((item) => {
          res.body.existence.forEach((value) => {
            if (item === value.problem_id + "") {
              realSort.push(value);
            }
          });
        });
        this.multipleSelection = this.multipleSelection.concat(realSort);
        for (let i = 0; i < this.multipleSelection.length; i++) {
          for (let j = 0; j < this.dataList.length; j++) {
            if (
              this.multipleSelection[i].problem_id ===
              this.dataList[j].problem_id
            ) {
              this.multipleSelection[j].choosetype = 1;
            }
          }
        }
        var obj = {};
        this.multipleSelection = this.multipleSelection.reduce(
          (result, item) => {
            obj[item.problem_id]
              ? ""
              : (obj[item.problem_id] = true && result.push(item));
            return result;
          },
          []
        );

        if (res.body.nonexistence.length === 0) {
          this.isNonentity = false;
        } else {
          this.seleErrTopic = res.body.nonexistence;
          this.isNonentity = true;
        }
        if (
          res.body.existence.length !== 0 ||
          res.body.nonexistence.length !== 0
        ) {
          window.$msg(
            `导入成功的ID ${res.body.existence.length} 条  不存在的ID ${res.body.nonexistence.length} 条`,
            0
          );
        }
      }
      // 关闭popover
      this.$refs.popover.doClose();
    },
    setData(data = []) {
      this.multipleSelection = data;

      this.$refs.multipleTable.clearSelection();

      data.forEach((item) => {
        this.$refs.multipleTable.toggleRowSelection(item, true);
      });
    },
    // 携带问题的id标识，跳转题目详情页面
    quesDetail(row) {
      let routeData = this.$router.resolve({
        path: "/research/question-details",
        query: {
          problemID: row.problem_id,
          quesItem: JSON.stringify(row),
        },
      });
      window.open(routeData.href, "_blank");
    },
    selectRow(selection, row) {
      console.log(selection, row);
    },
    changCurrengOne() {
      this.pagination.currentPage = 1;
    },
    setSort() {
      const el = this.$refs.dragTable.$el.querySelectorAll(
        ".el-table__body-wrapper > table > tbody"
      )[0];
      Sortable.create(el, {
        ghostClass: "sortable-ghost",
        setData: (dataTransfer) => {
          dataTransfer.setData("Text", "");
        },
        onEnd: (evt) => {
          const targetRow = this.seleTopic.splice(evt.oldIndex, 1)[0];
          this.seleTopic.splice(evt.newIndex, 0, targetRow);
          const tempIndex = this.newList.splice(evt.oldIndex, 1)[0];
          this.newList.splice(evt.newIndex, 0, tempIndex);
          this.multipleSelection.splice(
            evt.newIndex,
            1,
            ...this.multipleSelection.splice(
              evt.oldIndex,
              1,
              this.multipleSelection[evt.newIndex]
            )
          );
          this.$forceUpdate();
        },
      });
    },
    // 删除关联题目
    async deleteContestProblem(cpid, problemId) {
      const response = await deleteContestProblem({
        cpid: cpid,
      });
      if (response && response.state === "success") {
        for (let i = 0; i < this.dataList.length; i++) {
          if (this.dataList[i].problem_id === problemId) {
            this.dataList[i].choosetype = 0;
          }
        }
        for (let i = 0; i < this.seleTopic.length; i++) {
          if (this.seleTopic[i].problem_id === problemId) {
            this.seleTopic.splice(i, 1);
          }
        }
        window.$msg("删除成功");
        this.$nextTick(() => {
          this.$refs.heightSearch.search();
        });
        // this.getQuestionList();
      }
    },
    // 删除
    deleteItem(row, index) {
      this.$confirm("是否删除此题目", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.multipleSelection.splice(index, 1);
          this.$refs.multipleTable.toggleRowSelection(row, false);
          window.$msg("删除成功");

          // console.log(row)
          // if (row.cpid === null || row.cpid === undefined) {
          //   this.multipleSelection.splice(index, 1)
          //   // this.setData(this.multipleSelection)
          //
          //   this.$refs.multipleTable.toggleRowSelection(row, false)
          //
          //
          // } else {
          //   this.deleteContestProblem(row.cpid, row.problem_id)
          // }
        })
        .catch(() => {
          return false;
        });
    },
    async updateQuestionLibrary() {
      let problem_ids = "";
      if (this.seleTopic.length === 0) {
        window.$msg("请先选择题目", 2);
        return false;
      } else {
        this.seleTopic.forEach((item) => {
          if (problem_ids === "") {
            problem_ids = item.problem_id;
          } else {
            problem_ids = problem_ids + "," + item.problem_id;
          }
        });
      }

      const response = await updateQuestionLibrary({
        contest_id: this.contest_id,
        problem_ids: problem_ids,
      });
      if (response && response.state === "success") {
        window.$msg("题目编辑成功");
        this.$emit("updateView");
      }
    },
    getTagGroupList(activeName) {
      getTagGroupList({
        pageNum: 1,
        pageSize: 100,
        businessType: "oj",
        groupS:
          activeName === "first"
            ? "知识点"
            : activeName === "second"
            ? "难度"
            : "来源",
      }).then((res) => {
        if (res.state === "success") {
          const tagTreeData = [];

          res.body.list.forEach((ele, idx) => {
            tagTreeData.push({
              ...ele,
            });
          });
          getTags({
            pageNum: 1,
            pageSize: 10000,
            businessType: "oj",
            groupS:
              activeName === "first"
                ? "知识点"
                : activeName === "second"
                ? "难度"
                : "来源",
          }).then((res) => {
            if (res.state === "success") {
              let tagList = res.body;
              tagTreeData.forEach((ele, idx) => {
                tagList.forEach((item, index) => {
                  if (item.groupId === ele.id) {
                    // ele.children = new
                    if (!ele.children) {
                      ele.children = [];
                      ele.children.push({
                        ...item,
                      });
                    } else {
                      ele.children.push({
                        ...item,
                      });
                    }
                  }
                });
              });
              activeName === "first"
                ? (this.tagTreeDatafirst = tagTreeData)
                : activeName === "second"
                ? (this.tagTreeDatasecond = tagTreeData)
                : (this.tagTreeDatathird = tagTreeData);
              (this.tagTreeData = [
                {
                  title: "知识点",
                  children: this.tagTreeDatafirst,
                },
              ]),
                (this.difficultyList = [
                  {
                    title: "难度",
                    children: this.tagTreeDatasecond,
                  },
                ]),
                (this.sourceList = [
                  {
                    title: "来源",
                    children: this.tagTreeDatathird,
                  },
                ]);
            }
          });
        }
      });
    },
    // 遍历树形结构
    getTreeArray(data, val) {
      for (let i in data) {
        if (data[i].id === val[0]) {
          if (data[i].pid !== "") {
            this.formData.selected.unshift(data[i].pid);
            this.getTreeArray(this.categoryList, this.formData.selected);
          }
        } else {
          if (data[i].hasOwnProperty("children")) {
            this.getTreeArray(data[i].children, val);
          }
        }
      }
    },
    // 选择标签
    handleSeleTag(tagId) {
      let problemIds = "";
      problemIds = tagId[0];
      for (let i = 1; i < tagId.length; i++) {
        problemIds = problemIds + "," + tagId[i];
      }
      if (problemIds === undefined) {
        this.queryData.tagId = null;
      } else {
        this.queryData.tagId = problemIds;
      }
    },

    // 获取题目列表
    async getQuestionList(copyQueryBeanList, currentPage = "") {
      if (currentPage) {
        this.pagination.currentPage = currentPage;
      }
      const res = await listByGeneral({
        defunct: "N",
        needCount: true,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        queryBeanList: copyQueryBeanList,
      });
      this.pagination.total = res.body.total;
      for (let i = 0; i < res.body.list.length; i++) {
        res.body.list[i].choosetype = 0;
      }
      this.total = res.body.total;
      this.dataList = res.body.list;

      for (let i = 0; i < this.seleTopic.length; i++) {
        for (let j = 0; j < this.dataList.length; j++) {
          if (this.seleTopic[i].problem_id === this.dataList[j].problem_id) {
            this.dataList[j].choosetype = 1;
          }
        }
      }
    },

    // 获取启用的标签
    async getTags() {
      const res = await getTags({
        pageNum: 1,
        pageSize: 10000,
        businessType: "oj",
      });
      this.tagList = res.body;
    },

    jointArr(row) {
      let tagArr = [];
      tagArr = row.split(",");
      return tagArr;
    },

    // 重置
    reset() {
      this.queryData = {
        inputVal: "",
        select: "1",
        seleTag: "",
        tagId: "",
      };
    },
    // 查询题目
    search() {
      if (this.queryData.select === "2") {
        let reg = /^[0-9]*$/;
        if (!reg.test(this.queryData.inputVal)) {
          window.$msg("请输入正确的id", 2);
          return false;
        }
      }
      this.pagination.currentPage = 1;
      // this.getQuestionList();
      this.$nextTick(() => {
        this.$refs.heightSearch.search();
      });
    },

    handleSeleChange(val) {
      this.multipleSelection = val;
      this.seleTopic.push(...val);
    },
    // 分页
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      // this.getQuestionList();
      this.$nextTick(() => {
        this.$refs.heightSearch.search();
      });
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.$nextTick(() => {
        this.$refs.heightSearch.search();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .label-item-left {
  margin-top: 20px;
}

.footerparent {
  display: flex;
  justify-content: center;
}

.tag {
  display: inline-block;
  color: #fff;
  border-radius: 17px 20px 0px 17px;
  padding: 3px 15px;
  margin-right: 10px;
  margin-bottom: 8px;
}

.haschoose {
  margin-top: 20px;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.15);
  padding: 20px;
  padding-top: 0px;
}

.seleRow {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  background: #efefef;
  box-sizing: border-box;
  padding-left: 25px;
}

.seleNum {
  color: #fff;
  border-radius: 50%;
  padding: 1px 8px;
  background: #cccccc;
}
</style>
