<template>
  <div>
    <el-form
      class="formParent"
      ref="formData"
      :model="formData"
      :rules="rules"
      label-width="140px"
      label-position="right"
    >
      <el-form-item label="竞赛标题：" prop="title">
        <el-input
          v-model="formData.title"
          placeholder="请输入竞赛名称"
          maxlength="20"
          style="width: 400px"
        ></el-input>
      </el-form-item>
      <el-form-item label="竞赛类型：" prop="raceType">
        <el-radio-group @input="handleChangeType" :value="formData.raceType">
          <el-radio
            v-for="item in raceTypeList"
            :key="item.value"
            :label="item.value"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="赛制：" prop="raceRule" v-if="visible && isNormal">
        <el-radio-group v-model="formData.raceRule" @change="$forceUpdate()">
          <el-radio
            v-for="item in raceRuleList"
            :key="item.value"
            :label="item.value"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
        <el-tooltip
          class="item"
          effect="dark"
          content="CA表示刷题类型的竞赛，竞赛模式创建完成不可更改"
          placement="right"
        >
          <i
            class="el-icon-question"
            style="font-size: 18px; margin-left: 20px"
          ></i>
        </el-tooltip>
        <el-checkbox
          v-if="['OI', 'IOI'].includes(formData.raceRule)"
          style="margin-left: 10px"
          v-model="formData.timeCostRank"
          true-label="Y"
          false-label="N"
        >
          统计排名时计算用时
        </el-checkbox>
      </el-form-item>
      <el-form-item
        label="报名方式："
        prop="isPrivate"
        v-if="visible && isNormal"
      >
        <el-radio-group
          v-model="formData.isPrivate"
          @input="handleChangePrivate"
        >
          <el-radio :label="0">公开赛</el-radio>
          <el-radio :label="1">私有赛</el-radio>
        </el-radio-group>
        <el-tooltip class="item" effect="dark" placement="right">
          <span slot="content">
            <p>公开赛，允许学习平台用户查看和报名</p>
            <p>私有赛，用户不能主动报名，只能有竞赛管理员手动添加参赛成员</p>
          </span>
          <i
            class="el-icon-question"
            style="font-size: 18px; margin-left: 20px"
          ></i>
        </el-tooltip>
      </el-form-item>
      <el-form-item
        v-if="visible && isNormal && formData.isPrivate === 0"
        label="是否审核报名："
        prop="isVerify"
      >
        <el-radio-group v-model="formData.isVerify">
          <el-radio label="Y">是</el-radio>
          <el-radio label="N">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="visible && isNormal && formData.isPrivate !== 1"
        label="报名时间："
        prop="applyTime"
      >
        <el-date-picker
          @change="$forceUpdate()"
          @blur="$forceUpdate()"
          v-model="formData.applyTime"
          type="datetimerange"
          start-placeholder="活动报名开始时间"
          end-placeholder="活动报名结束时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00']"
        ></el-date-picker>
        <!--        <el-checkbox-->
        <!--            v-if="formData.raceType === '刷题题集'"-->
        <!--            style="margin-left: 15px"-->
        <!--            v-model="applychecked"-->
        <!--        >不限时-->
        <!--        </el-checkbox>-->
      </el-form-item>
      <el-form-item
        label="竞赛时间："
        prop="contestTime"
        v-if="visible && ['普通竞赛', '极速竞赛'].includes(formData.raceType)"
      >
        <el-date-picker
          @change="$forceUpdate()"
          @blur="$forceUpdate()"
          v-model="formData.contestTime"
          type="datetimerange"
          start-placeholder="竞赛开始时间"
          end-placeholder="竞赛结束时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00']"
        ></el-date-picker>
        <!--        <el-checkbox-->
        <!--            v-if="formData.raceType === 4"-->
        <!--            style="margin-left: 15px"-->
        <!--            v-model="checked"-->
        <!--        >不限时-->
        <!--        </el-checkbox>-->
      </el-form-item>
      <el-form-item label="可用语言：" prop="langmask" v-if="visible">
        <el-select
          v-model="formData.langmask"
          @change="$forceUpdate()"
          multiple
          filterable
          placeholder="请选择编程语言"
        >
          <el-option
            v-for="item in languageList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="允许复制代码：" prop="codeOpen" v-if="visible">
        <el-radio-group v-model="formData.codeOpen">
          <el-radio label="y">是</el-radio>
          <el-radio label="n">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="开放题解：" prop="solutionVideoOpen" v-if="visible">
        <el-radio-group v-model="formData.solutionVideoOpen">
          <el-radio label="Y">是</el-radio>
          <el-radio label="N">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="答题时长："
        v-if="visible && isSpeed"
        prop="duration"
      >
        <el-input-number
          v-model="formData.duration"
          placeholder="请输入答题时长"
          :min="1"
        ></el-input-number>
        <span style="margin-left: 8px">min</span>
      </el-form-item>
      <el-form-item
        label="开放排行榜："
        v-if="visible && isSpeed"
        prop="rankAnnounce"
      >
        <el-radio-group v-model="formData.rankAnnounce">
          <el-radio label="Y">是</el-radio>
          <el-radio label="N">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="竞赛介绍：" v-if="visible">
        <Tinymce
          v-model="formData.description"
          width="100%"
          ref="editor"
          :height="500"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Tinymce from "@/components/newTinymce";
import { langmask } from "@/utils/langmask";
// import {  } from "@/api/operation";

const RACE_TYPE_LIST = [{ label: "刷题题集", value: "刷题题集" }];

const RACE_RULE_LIST = [
  { label: "ACM", value: "ACM" },
  { label: "OI", value: "OI" },
  { label: "IOI", value: "IOI" },
];
export default {
  name: "basicInfo",
  model: {
    prop: "value",
    event: "change",
  },
  components: {
    Tinymce,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Number,
      default: 0,
    },
    // 是否是编辑
    isEditDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let RichTimeVal = (rule, value, callback) => {
      if (!this.formData.applyTime || this.formData.applyTime.length === 0) {
        callback(new Error("请选择报名时间"));
      } else {
        callback();
      }
    };
    let contestTimeVal = (rule, value, callback) => {
      if (
        !this.formData.contestTime ||
        this.formData.contestTime.length === 0
      ) {
        callback(new Error("请选择竞赛时间"));
      } else {
        callback();
      }
    };
    return {
      normalizer(node) {
        return {
          ...node,
          label: node.name,
          value: node.id,
        };
      },
      listLevel: [], // 课程系列
      raceTypeList: RACE_TYPE_LIST,
      raceRuleList: RACE_RULE_LIST,
      languageList: langmask,
      formData: {
        // 公共form
        contestTime: [],
        raceType: "刷题题集",
        timeCostRank: "N",
        title: "", //
        description: "", // 竞赛介绍
        langmask: ["1"], // 可用语言
        codeOpen: "y", // 复制代码
        solutionVideoOpen: "N", // 开放题解
        //
        raceRule: "", // 赛制
        isPrivate: null, // 报名方式
        isVerify: "", // 是否审核报名
        applyTime: "", // 报名时间
        //
        duration: null, // 答题时长
        rankAnnounce: "N", // 开放排行榜
        catalogLearnId: null, // 课程系列
      },
      rules: {
        timeCostRank: "N",
        codeOpen: {
          required: true,
          message: "是否允许复制代码选择不能为空",
          trigger: "change",
        }, // 复制代码
        solutionVideoOpen: {
          required: true,
          message: "开放题解选择不能为空",
          trigger: "change",
        }, // 开放题解
        duration: {
          required: true,
          message: "答题时长不能为空",
          trigger: "blur",
        }, // 答题时长
        rankAnnounce: {
          required: true,
          message: "开放排行榜选择不能为空",
          trigger: "change",
        }, // 开放排行榜
        isVerify: {
          required: true,
          message: "是否审核报名选择不能为空",
          trigger: "change",
        }, // 是否审核报名
        title: [
          { required: true, message: "竞赛标题不能为空", trigger: "blur" },
          { max: 20, message: "字数限制在20个字内", trigger: "blur" },
        ],

        raceRule: {
          required: true,
          message: "赛制不能为空",
          trigger: "change",
        },
        // catalogLearnId: { required: true, message: '竞赛类别不能为空', trigger: 'blur' },
        isPrivate: {
          required: true,
          message: "报名方式不能为空",
          trigger: "blur",
        },
        applyTime: { required: true, validator: RichTimeVal, trigger: "blur" },
        raceType: {
          required: true,
          message: "请选择竞赛类型",
          trigger: "blur",
        },
        contestTime: {
          required: true,
          validator: contestTimeVal,
          trigger: "blur",
        },
        langmask: {
          required: true,
          message: "请选择编程语言",
          trigger: "blur",
        },
      },
    };
  },
  computed: {
    isNormal() {
      return ["普通竞赛"].includes(this.formData.raceType);
    },
    isSpeed() {
      return ["极速竞赛"].includes(this.formData.raceType);
    },
    isTopic() {
      return ["刷题题集"].includes(this.formData.raceType);
    },
    // 默认展示
    visible() {
      return this.isNormal || this.isSpeed || this.isTopic;
    },
    isEdit() {
      return this.isEditDisabled;
    },
  },

  mounted() {
    this.formData.raceType = "刷题题集";
  },

  methods: {
    handleChangePrivate(value) {
      if (value === 1) {
        this.formData.applyTime = [];
      }
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs["formData"].validate((valid) => {
          if (valid) {
            const date =
              new Date(this.formData.applyTime[1]).getTime() >
              new Date(this.formData.contestTime[1]).getTime();
            if (date && this.formData.isPrivate === 1) {
              this.$message.warning("报名结束时间不能大于竞赛结束时间");
              // eslint-disable-next-line prefer-promise-reject-errors
              reject([false, {}]);
            } else {
              resolve([valid, this.formData]);
            }
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject([false, {}]);
          }
        });
      });
    },
    handleChangeType(label) {
      if (this.formData.raceType) {
        this.$confirm("切换竞赛类型会清空当前配置, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.resetForm();
            this.formData.raceType = label;
            this.$emit("changeType", label);
          })
          .catch(() => {});
      } else {
        this.formData.raceType = label;
        this.$emit("changeType", label);
      }
    },
    // 充值
    resetForm() {
      this.$refs["formData"].clearValidate();
      this.$refs["editor"].setContent("");

      this.formData = {
        contestTime: [],
        raceType: "",
        timeCostRank: "N",
        description: "", // 竞赛介绍
        langmask: ["1"], // 可用语言
        codeOpen: "y", // 复制代码
        solutionVideoOpen: "N", // 开放题解
        //
        type: "", // 赛制
        isPrivate: null, // 报名方式
        isVerify: "", // 是否审核报名
        applyTime: "", // 报名时间
        //
        duration: null, // 答题时长
        rankAnnounce: "N", // 开放排行榜
        title: this.formData.title,
        catalogLearnId: null,
      };
    },
  },
};
</script>

<style scoped></style>
