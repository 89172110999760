var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 14 } },
            [
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v("待选题目："),
              ]),
              _c(
                "el-row",
                { staticClass: "label-item-left", attrs: { gutter: 10 } },
                [
                  _c("heightSearch", {
                    ref: "heightSearch",
                    attrs: {
                      sourceList: _vm.sourceList,
                      tagTreeData: _vm.tagTreeData,
                      difficultyList: _vm.difficultyList,
                      knowledgeList: _vm.tagTreeData,
                    },
                    on: {
                      changCurrengOne: _vm.changCurrengOne,
                      getQuestionList: _vm.getQuestionList,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "label-item-left" },
                [
                  _c(
                    "el-table",
                    {
                      ref: "multipleTable",
                      staticClass: "tb-list",
                      attrs: {
                        data: _vm.dataList,
                        border: "",
                        "row-key": "problem_id",
                        fit: "",
                        "highlight-current-row": "",
                      },
                      on: { "selection-change": _vm.handleSeleChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          "reserve-selection": "",
                          type: "selection",
                          width: "55",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "问题ID",
                          align: "center",
                          width: "120",
                          prop: "problem_id",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": "",
                          label: "问题标题",
                          align: "left",
                          prop: "title",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-link",
                                  {
                                    staticClass: "one-line",
                                    attrs: { type: "primary", underline: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.quesDetail(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(scope.row.title) + " ")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "标签",
                          align: "left",
                          prop: "title",
                          width: "300",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return _vm._l(row.tagList, function (item) {
                                return _c(
                                  "el-tag",
                                  {
                                    key: item.id,
                                    staticStyle: {
                                      margin: "0 6px",
                                      border: "none",
                                    },
                                    attrs: {
                                      size: "mini",
                                      color: item.groupInfo.color,
                                      effect: "dark",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.title) + " ")]
                                )
                              })
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "提交记录",
                          align: "left",
                          prop: "title",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "pointer",
                                    on: {
                                      click: function ($event) {
                                        return _vm.subRecord(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(scope.row.accepted) +
                                        "/" +
                                        _vm._s(scope.row.submit)
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.pagination.currentPage,
                  "page-sizes": _vm.pagination.pageSizes,
                  "page-size": _vm.pagination.pageSize,
                  layout: _vm.pagination.layout,
                  total: _vm.pagination.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v("已选题目："),
              ]),
              _c(
                "el-popover",
                {
                  ref: "popover",
                  style: {
                    "margin-left": "10px",
                    position: "absolute",
                    right: "4px",
                    top: "0px",
                    "z-index": "99",
                  },
                  attrs: { placement: "right", width: "400", trigger: "click" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder:
                        "4443\n4444\n4445\n4446\n……\n……\n请按每行一个的数字格式填写要添加的题目，点击导入按钮",
                      rows: "8",
                      "show-word-limit": "",
                    },
                    on: {
                      input: function ($event) {
                        _vm.batchStr = _vm.batchStr.replace(/[^\d\r'\n']/g, "")
                      },
                    },
                    model: {
                      value: _vm.batchStr,
                      callback: function ($$v) {
                        _vm.batchStr = $$v
                      },
                      expression: "batchStr",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      attrs: { slot: "reference", size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.batchStr = ""
                        },
                      },
                      slot: "reference",
                    },
                    [_vm._v("批量添加 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.batchAddId },
                    },
                    [_vm._v("导入 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "haschoose" },
                [
                  _c(
                    "el-table",
                    {
                      ref: "dragTable",
                      staticClass: "tb-list",
                      attrs: {
                        height: "500",
                        data: _vm.multipleSelection,
                        border: "",
                        fit: "",
                        "row-key": "problem_id",
                        "highlight-current-row": "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "题号", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(" " + _vm._s(scope.$index + 1) + " "),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "ID",
                          align: "center",
                          prop: "problem_id",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "标题",
                          align: "center",
                          prop: "title",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("i", {
                                  staticClass: "el-icon-sort",
                                  staticStyle: {
                                    "font-size": "16px",
                                    "margin-right": "10px",
                                    cursor: "pointer",
                                  },
                                }),
                                _c("i", {
                                  staticClass: "el-icon-view",
                                  staticStyle: {
                                    "font-size": "16px",
                                    "margin-right": "10px",
                                    cursor: "pointer",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.quesDetail(scope.row)
                                    },
                                  },
                                }),
                                _c("i", {
                                  staticClass: "el-icon-delete-solid",
                                  staticStyle: {
                                    "font-size": "16px",
                                    cursor: "pointer",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteItem(
                                        scope.row,
                                        scope.$index
                                      )
                                    },
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.isNonentity
                ? _c(
                    "el-card",
                    {
                      staticClass: "haschoose",
                      staticStyle: { "margin-top": "25px" },
                      style: { height: "500px" },
                      attrs: { shadow: "nover" },
                    },
                    [
                      _c(
                        "h3",
                        {
                          staticStyle: {
                            color: "gray",
                            "margin-bottom": "10px",
                            "border-bottom": "1px solid rgba(16, 31, 28, 0.1)",
                          },
                        },
                        [
                          _vm._v(
                            " 不存在的ID(" +
                              _vm._s(_vm.seleErrTopic.length) +
                              ") "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "overflow-y": "auto",
                            height: "450px",
                          },
                        },
                        _vm._l(_vm.seleErrTopic, function (item, index) {
                          return _c("el-col", { key: index }, [
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "margin-bottom": "10px",
                                  "border-bottom":
                                    "1px solid rgba(16, 31, 28, 0.1)",
                                },
                              },
                              [_vm._v(" " + _vm._s(item) + " ")]
                            ),
                          ])
                        }),
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }