<template>
  <div class="main" style="padding-left: 16px; padding-bottom: 16px">
    <div class="maxMain">
      <!-- 条件组 -->
      <el-form
        :ref="`initForm${index}`"
        v-for="(item, index) in queryBeanList"
        :key="index"
      >
        <div v-if="queryBeanList.length > 1" class="conditionNum">
          条件组{{ index + 1 }}
        </div>
        <div style="display: flex; flex-wrap: wrap">
          <!-- @mouseenter.native="delIndex = listFormIndex" -->
          <!-- :label="listFormItem.name ? `${listFormItem.name}:` : ''"
            :label-width="listFormItem.name == '提交记录数' ? '110px' : '80px'" -->
          <el-form-item
            v-for="(listFormItem, listFormIndex) in item.paramList"
            @mouseenter.native="mouseenter(index, listFormIndex)"
            v-if="listFormItem.isShow"
          >
            <template v-if="listFormItem.targetId == '1047'">
              <!-- style="margin-left: -70px" -->
              <!-- <el-checkbox
                v-model="listFormItem.condition"
                true-label="Exist"
                false-label="NotExist"
              > -->
              <!-- 是否有题解视频 -->
              <!-- </el-checkbox> -->
              <span slot="label">题解视频：</span>
              <el-select
                v-model="listFormItem.condition"
                clearable
                placeholder="请选择"
              >
                <el-option key="有" label="有" value="Exist"> </el-option>
                <el-option key="无" label="无" value="NotExist"> </el-option>
              </el-select>
            </template>
            <template v-if="listFormItem.targetId == '1048'">
              <span slot="label" style="margin-left: 12px">标题：</span>
              <el-input
                clearable
                placeholder="请输入标题"
                v-model.trim="listFormItem.value"
                size="small"
                @keyup.enter.native="searchPro"
              />
            </template>
            <template v-if="listFormItem.targetId == '1053'">
              <span slot="label" style="margin-left: 12px">ID：</span>
              <el-input
                clearable
                placeholder="请输入ID"
                v-model.trim="listFormItem.value"
                size="small"
                @keyup.enter.native="searchPro"
              />
            </template>
            <template v-if="listFormItem.targetId == '1052'">
              <span slot="label" style="margin-left: 12px">来源：</span>
              <el-cascader
                style="width: 200px"
                size="small"
                v-model="listFormItem.value"
                :ref="`sourceCascader${index}`"
                :options="sourceList"
                collapse-tags
                placeholder="请选择来源"
                :props="props"
                filterable
                clearable
              ></el-cascader>
            </template>
            <template v-if="listFormItem.targetId == '1050'">
              <span slot="label" style="margin-left: 12px">知识点：</span>
              <el-cascader
                style="width: 240px"
                size="small"
                v-model="listFormItem.value"
                :ref="`knowledgeCascader${index}`"
                :options="knowledgeList"
                collapse-tags
                placeholder="请选择知识点"
                :props="props"
                filterable
                clearable
              ></el-cascader>
            </template>
            <template v-if="listFormItem.targetId == '1051'">
              <span slot="label" style="margin-left: 12px">难度：</span>
              <el-cascader
                size="small"
                v-model="listFormItem.value"
                :ref="`difficultyCascader${index}`"
                :options="difficultyList"
                collapse-tags
                filterable
                style="width: 240px"
                placeholder="请选择难度"
                :props="props"
                clearable
              ></el-cascader>
            </template>
            <template v-if="listFormItem.targetId == '1049'">
              <span slot="label" style="margin-left: 12px">状态：</span>
              <el-select
                v-model="listFormItem.value"
                placeholder="选择状态"
                class="w100"
                @change="handleSeleState"
              >
                <el-option
                  v-for="item in queState"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </template>
            <template v-if="listFormItem.targetId == '1045'">
              <span slot="label" style="margin-left: 12px">提交记录数：</span>
              <div class="flex">
                <!-- class="w100" -->
                <el-select
                  v-model="listFormItem.condition"
                  size="small"
                  style="width: 120px"
                  placeholder="请输入提交记录数"
                  @change="selectChange('scopeTypeSubNum')"
                >
                  <el-option
                    v-for="items in scopeTypeList"
                    :key="items.value"
                    :label="items.label"
                    :value="items.value"
                  >
                  </el-option>
                </el-select>
                <!-- 小于,大于,小于等于,大于等于,等于,不等于 -->
                <el-input
                  v-if="listFormItem.condition != 'Between'"
                  v-model="listFormItem.value"
                  placeholder="请输入提交记录数"
                  clearable
                  min="0"
                  class="w180"
                  size="small"
                  @keyup.enter.native="searchPro"
                ></el-input>
                <div v-else class="flex alignCenter" style="width: 100px">
                  <el-input
                    v-model="listFormItem.value"
                    style="width: 140px"
                    @keyup.enter.native="searchPro"
                  ></el-input>
                  <span>~</span>
                  <el-input
                    v-model="listFormItem.rightValue"
                    style="width: 140px"
                    @keyup.enter.native="searchPro"
                  ></el-input>
                </div>
              </div>
            </template>
            <template v-if="listFormItem.targetId == '1046'">
              <span slot="label" style="margin-left: 12px">用例数：</span>
              <div class="flex">
                <el-select
                  v-model="listFormItem.condition"
                  size="small"
                  style="width: 120px"
                  placeholder="请输入用例数"
                  @change="selectChange('scopeTypeSubNum')"
                >
                  <el-option
                    v-for="items in scopeTypeList"
                    :key="items.value"
                    :label="items.label"
                    :value="items.value"
                  >
                  </el-option>
                </el-select>
                <!-- 小于,大于,小于等于,大于等于,等于,不等于 -->
                <el-input
                  v-if="listFormItem.condition != 'Between'"
                  v-model="listFormItem.value"
                  placeholder="请输入提交记录数"
                  clearable
                  min="0"
                  class="w180"
                  size="small"
                  @keyup.enter.native="searchPro"
                ></el-input>
                <div v-else class="flex alignCenter" style="width: 100px">
                  <el-input
                    v-model="listFormItem.value"
                    style="width: 140px"
                    @keyup.enter.native="searchPro"
                  ></el-input>
                  <span>~</span>
                  <el-input
                    v-model="listFormItem.rightValue"
                    style="width: 140px"
                    @keyup.enter.native="searchPro"
                  ></el-input>
                </div>
              </div>
            </template>
            <!-- 删除搜索条件 -->
            <!-- <i
              class="el-icon-remove removeIcon"
              v-if="
                String(index) + String(listFormIndex) == delIndex &&
                showDelIcon &&
                isShowDelIcon
              "
              @click="delSearchItem(item, index, listFormItem, listFormIndex)"
            ></i> -->
          </el-form-item>
        </div>
        <el-divider
          content-position="center"
          v-if="queryBeanList.length > 1 && index != queryBeanList.length - 1"
          >或</el-divider
        >
      </el-form>
    </div>
    <div class="search">
      <el-dropdown split-button size="small" @click="conditionAdd('condition')">
        添加条件
        <el-dropdown-menu slot="dropdown">
          <el-tooltip
            effect="dark"
            content="条件组最多支持三个"
            placement="top-start"
          >
            <el-dropdown-item
              @click.native="conditionAdd('group')"
              :disabled="queryBeanList.length == 3"
              >添加条件组</el-dropdown-item
            >
          </el-tooltip>
        </el-dropdown-menu>
      </el-dropdown>
      <el-button
        type="primary"
        size="small"
        style="margin-left: 10px"
        @click="searchPro"
        >过滤</el-button
      >
      <el-button size="small" @click="research">重置</el-button>
    </div>
    <!-- 添加条件/条件组 -->
    <conditionAdd
      :conditionDialog.sync="conditionDialog"
      v-if="conditionDialog"
      :queryBeanList="queryBeanList"
      :conditionType="conditionType"
      @confirmCondition="confirmCondition"
      @confirmConditionGroup="confirmConditionGroup"
      @delAlreadyGroup="delAlreadyGroup"
    />
  </div>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
import conditionAdd from "./conditionAdd.vue";
export default {
  components: { conditionAdd },
  props: {
    // 来源
    sourceList: {
      type: Array,
      default: () => [],
    },
    // 标签树形结构数据
    tagTreeData: {
      type: Array,
      default: () => [],
    },
    // 难易度
    difficultyList: {
      type: Array,
      default: () => [],
    },
    // 知识点
    knowledgeList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // 控制删除搜索条件的下表
      delIndex: null,
      // 条件删除按钮
      showDelIcon: true,
      // 条件弹窗
      conditionDialog: false,
      // 添加条件的类型
      conditionType: "condition", // 条件：condition，条件组：group
      // 高级查询条件列表
      queryBeanList: [
        {
          // 条件组
          paramList: [
            // 题解视频（NotExist：无，Exist：有）
            {
              name: "题解视频",
              condition: "",
              targetId: "1047",
              isShow: false,
            },
            // 标题
            {
              name: "标题",
              condition: "Like",
              targetId: "1048",
              value: null,
              isShow: true,
            },
            // 关键字id
            {
              name: "ID",
              condition: "Eq",
              targetId: "1053",
              value: null,
              isShow: true,
            },
            // 来源
            {
              name: "来源",
              condition: "In",
              targetId: "1052",
              value: null,
              isShow: true,
            },
            // 知识点
            {
              name: "知识点",
              condition: "In",
              targetId: "1050",
              value: null,
              isShow: true,
            },
            // 难易度
            {
              name: "难易度",
              condition: "In",
              targetId: "1051",
              value: null,
              isShow: false,
            },
            // 状态
            {
              name: "状态",
              condition: "Eq",
              targetId: "1049",
              value: "N",
              isShow: false,
            },
            // 提交记录数
            {
              name: "提交记录数",
              condition: "Eq",
              targetId: "1045",
              value: null,
              rightValue: null,
              isShow: false,
            },
            // 用例数
            {
              name: "用例数",
              condition: "Eq",
              targetId: "1046",
              value: null,
              isShow: false,
            },
          ],
        },
      ],
      // 状态
      queState: [
        {
          label: "已停用",
          value: "Y",
        },
        {
          label: "已启用",
          value: "N",
        },
      ],
      props: { multiple: true, value: "id", label: "title" },
      // 范围选择
      scopeTypeList: [
        // {
        //   value: "Lt",
        //   label: "小于",
        // },
        // {
        //   value: "Gt",
        //   label: "大于",
        // },
        {
          value: "LtAndEq",
          label: "小于等于",
        },
        {
          value: "GtAndEq",
          label: "大于等于",
        },
        {
          value: "Eq",
          label: "等于",
        },
        // {
        //   value: "Neq",
        //   label: "不等于",
        // },
        {
          value: "Between",
          label: "介于",
        },
      ],
    };
  },
  watch: {},
  computed: {
    isShowDelIcon() {
      if (this.queryBeanList.length == 1) {
        let isShowArr = this.queryBeanList[0].paramList.filter((item) => {
          return item.isShow;
        });
        if (isShowArr.length == 1) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    searchPro() {
      this.$emit("changCurrengOne");
      this.search();
    },
    // 重置
    research() {
      (this.queryBeanList = [
        {
          // 条件组
          paramList: [
            // 题解视频（NotExist：无，Exist：有）
            {
              name: "题解视频",
              condition: "",
              targetId: "1047",
              isShow: true,
            },
            // 标题
            {
              name: "标题",
              condition: "Like",
              targetId: "1048",
              value: null,
              isShow: true,
            },
            // 关键字id
            {
              name: "ID",
              condition: "Eq",
              targetId: "1053",
              value: null,
              isShow: false,
            },
            // 来源
            {
              name: "来源",
              condition: "In",
              targetId: "1052",
              value: null,
              isShow: true,
            },
            // 知识点
            {
              name: "知识点",
              condition: "In",
              targetId: "1050",
              value: null,
              isShow: false,
            },
            // 难易度
            {
              name: "难易度",
              condition: "In",
              targetId: "1051",
              value: null,
              isShow: false,
            },
            // 状态
            {
              name: "状态",
              condition: "Eq",
              targetId: "1049",
              value: "N",
              isShow: false,
            },
            // 提交记录数
            {
              name: "提交记录数",
              condition: "Eq",
              targetId: "1045",
              value: null,
              rightValue: null,
              isShow: false,
            },
            // 用例数
            {
              name: "用例数",
              condition: "Eq",
              targetId: "1046",
              value: null,
              isShow: false,
            },
          ],
        },
      ]),
        this.searchPro();
    },
    changeInputEvent(item, index, listFormItem, listFormIndex, val) {
      this.queryBeanList[index].paramList[listFormIndex].value = val;
      // debugger;
      this.$forceUpdate();
    },
    search() {
      // return;
      this.$nextTick(() => {
        let copyQueryBeanList = cloneDeep(this.queryBeanList);
        // console.log("this.queryBeanList", this.queryBeanList);
        // console.log("copyQueryBeanList", copyQueryBeanList);
        let videoSpliceIndex = [];
        for (let i = 0; i < copyQueryBeanList.length; i++) {
          for (let k = 0; k < copyQueryBeanList[i].paramList.length; k++) {
            let item = copyQueryBeanList[i].paramList[k];
            console.log("item", item);
            // 来源
            if (item.targetId == "1052") {
              if (item.value) {
                let sourceTagIds = [];
                this.$refs[`sourceCascader${i}`][0]
                  .getCheckedNodes(true)
                  .forEach((items) => {
                    sourceTagIds.push(items.value);
                  });
                console.log("sourceTagIds", sourceTagIds);
                copyQueryBeanList[i].paramList[k].valueList =
                  sourceTagIds || null;
              } else {
                copyQueryBeanList[i].paramList[k].valueId = null;
                copyQueryBeanList[i].paramList[k].value = null;
                copyQueryBeanList[i].paramList[k].valueList = null;
              }
            }
            // 知识点
            if (item.targetId == "1050") {
              if (item.value) {
                let knowledgeTagIds = [];
                this.$refs[`knowledgeCascader${i}`][0]
                  .getCheckedNodes(true)
                  .forEach((items) => {
                    knowledgeTagIds.push(items.value);
                  });
                copyQueryBeanList[i].paramList[k].valueList = knowledgeTagIds;
              } else {
                copyQueryBeanList[i].paramList[k].valueId = null;
                copyQueryBeanList[i].paramList[k].value = null;
                copyQueryBeanList[i].paramList[k].valueList = null;
              }
            }
            // 难易度
            if (item.targetId == "1051") {
              if (item.value) {
                let difficultyTagIds = [];
                this.$refs[`difficultyCascader${i}`][0]
                  .getCheckedNodes(true)
                  .forEach((items) => {
                    difficultyTagIds.push(items.value);
                  });
                copyQueryBeanList[i].paramList[k].value = difficultyTagIds;
                copyQueryBeanList[i].paramList[k].valueList = difficultyTagIds;
              }
            }
            // 状态
            if (item.targetId == "1049") {
              if (!item.isShow) {
                copyQueryBeanList[i].paramList[k].value = null;
              }
            }
            // 提交记录数 || 用例数
            if (item.targetId == "1045" || item.targetId == "1046") {
              if (item.isShow) {
                // 介于
                if (item.condition == "Between") {
                  if (!item.value) {
                    copyQueryBeanList[i].paramList[k].value = null;
                  }
                  if (!item.rightValue) {
                    copyQueryBeanList[i].paramList[k].rightValue = null;
                  }
                }
              }
            }
            // 如果该项查询条件没有被勾选，就置为null
            if (!item.isShow) {
              copyQueryBeanList[i].paramList[k].condition = "Eq";
              copyQueryBeanList[i].paramList[k].value = null;
              // 如果是题解视频
              // if (item.name == "题解视频") {
              //   copyQueryBeanList[i].paramList[k].condition = null;
              //   // copyQueryBeanList[i].paramList.splice(k, 1);
              //   videoSpliceIndex.push({ i, k });
              // }
            }
            if (item.name == "题解视频") {
              if (!item.isShow || !item.condition) {
                videoSpliceIndex.push({ i, k });
              }
            }
            // 如果为空值，就置为null
            if (!item.value) {
              copyQueryBeanList[i].paramList[k].value = null;
            }
          }
        }
        // 题解视频如果没被勾选，就删掉他
        if (videoSpliceIndex.length) {
          for (let i = 0; i < videoSpliceIndex.length; i++) {
            console.log("videoSpliceIndex", videoSpliceIndex[i]);
            let indexObj = videoSpliceIndex[i];
            copyQueryBeanList[indexObj.i].paramList.splice(indexObj.k, 1);
          }
        }
        console.log("copyQueryBeanList", copyQueryBeanList);
        console.log("videoSpliceIndex", videoSpliceIndex);
        // this.$parent.pagination.currentPage = 1;
        this.$emit("getQuestionList", copyQueryBeanList);
      });
    },
    deepClone(obj) {
      var target = {};
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          if (typeof obj[key] === "object") {
            target[key] = this.deepClone(obj[key]);
          } else {
            target[key] = obj[key];
          }
        }
      }
      return target;
    },
    // 鼠标移入显示删除按钮
    mouseenter(index, listFormIndex) {
      this.delIndex = String(index) + String(listFormIndex);
    },
    // 添加条件/条件组-打开弹窗
    conditionAdd(type) {
      // // 条件
      // if(type == 'condition'){
      // }
      // // 条件组
      // else{
      // }
      // this.queryBeanList.push(this.queryBeanList[0]);
      this.conditionType = type;
      this.conditionDialog = true;
    },
    // 确认添加条件
    confirmCondition(conditionArr) {
      for (let i = 0; i < this.queryBeanList.length; i++) {
        for (let j = 0; j < this.queryBeanList[i].paramList.length; j++) {
          if (
            this.queryBeanList[i].paramList[j].targetId ==
              conditionArr[i].paramList[j].targetId &&
            conditionArr[i].paramList[j].isShow
          ) {
            this.queryBeanList[i].paramList[j].isShow = true;
          } else {
            this.queryBeanList[i].paramList[j].isShow = false;
          }
          let isShowArr = this.queryBeanList[i].paramList.filter((item) => {
            return item.isShow;
          });
          // 如果当前条件组的所有搜索条件都没有了，就删除当前条件组
          if (!isShowArr.length) {
            this.queryBeanList.splice(i, 1);
          }
        }
      }
    },
    // // 对已有的条件组进行删除
    delAlreadyGroup(index) {
      // this.queryBeanList[index].paramList.forEach(element => {
      //   this.queryBeanList[index].paramList
      // });
      this.queryBeanList.splice(index, 1);
      // for (let i = 0; i < this.queryBeanList[index].paramList.length; i++) {
      //   this.queryBeanList[index].paramList[i].isShow = false;
      // }
    },
    // 确认添加条件组
    confirmConditionGroup(newQueryBeanList) {
      this.queryBeanList.push(...newQueryBeanList);
    },
    // 删除搜索条件
    delSearchItem(item, index, listFormItem, listFormIndex) {
      this.queryBeanList[index].paramList[listFormIndex].isShow = false;
      // 此标志为是否显示条件删除按钮（删除到最后一个不允许删除）
      this.showDelIcon = true;
      if (
        this.queryBeanList.length == 1 &&
        this.queryBeanList[0].paramList.filter((item) => {
          return item.isShow;
        }).length == 1
      ) {
        this.showDelIcon = false;
      }
      // 如果当前条件组的所有条件都已删除， 则删除当前条件组
      let delArr = this.queryBeanList[index].paramList.filter((item) => {
        return item.isShow;
      });
      if (!delArr.length) {
        this.queryBeanList.splice(index, 1);
      }
    },
    // 状态变化
    handleSeleState(val) {
      // this.seleState = val;
    },
    //
    selectChange(type) {
      switch (
        type
        // case "age":
        //   this.queryParams.age = null;
        //   this.scopeTypeAgeVal.val1 = "";
        //   this.scopeTypeAgeVal.val2 = "";
        //   break;
        // case "OrderFollow":
        //   this.scopeTypeOrderFollowVal = "";
        //   this.queryParams.followTime = [];
        //   break;
        // case "CreateTime":
        //   this.scopeTypeCreateTimeVal = "";
        //   this.queryParams.createTime = [];
        //   break;
        // case "LastOrderFollow":
        //   this.scopeTypeLastOrderFollowVal = "";
        //   this.queryParams.lastFollowTime = [];
        //   break;
      ) {
      }
    },
  },
};
</script>
<style>
.flex {
  display: flex;
}
.alignCenter {
  align-items: center;
}
.w100 {
  width: 90px !important;
}
.w180 {
  width: 160px !important;
}
.w20 {
  width: 20px !important;
}
.w120 {
  width: 120px !important;
}
</style>
<style lang="scss" scoped>
.main {
  /* padding: 20px; */
}
/* /deep/ .el-form-item__content {
  display: flex !important;
} */
/deep/ .el-form-item--medium .el-form-item__content {
  display: flex !important;
  align-items: center;
}
.removeIcon {
  color: red;
  cursor: pointer;
  margin-left: 5px;
  font-size: 16px;
}
.search {
  margin-right: 150px;
  float: right;
  /* margin-top: 20px; */
}
.maxMain {
  max-height: 400px;
  overflow-y: auto;
}
.conditionNum {
  font-size: 14px;
  margin-bottom: 8px;
}
</style>
