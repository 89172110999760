<template>
  <div class="table">
    <div class="step-container">
      <el-steps
        :active="active"
        finish-status="success"
        style="margin-bottom: 15px; width: 500px"
      >
        <el-step title="竞赛设置" />
        <el-step title="选择题集模板" v-if="raceType !== '极速竞赛'"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>
      <basicInfo @changeType="changeType" ref="basic" v-show="active === 0" />

      <!-- <selectSubject ref="selectSubject" v-show="active === 1" /> -->
      <selectQuesSet ref="selectQuesSet" v-show="active === 1" />
      <!--    success   -->
      <el-row v-if="active === 2">
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 40px;
          "
        >
          <i
            class="el-icon-circle-check"
            style="font-size: 50px; color: #0ca51a"
          ></i>
          <span style="margin-top: 20px; font-weight: bold; font-size: 25px"
            >创建成功</span
          >
        </div>
      </el-row>
      <el-row slot="footer" class="footerparent">
        <!-- 非极速编程 -->
        <div v-if="raceType !== '极速竞赛'">
          <el-button
            v-if="active === 1"
            type="primary"
            @click="pre"
            size="small"
            >上一步
          </el-button>
          <el-button
            v-if="active === 0"
            type="primary"
            @click="next"
            size="small"
            >下一步
          </el-button>
          <el-button
            v-if="active === 1"
            @click="addQuestionLibrary"
            type="primary"
            size="small"
            >提交
          </el-button>
        </div>
        <div v-else>
          <el-button
            v-if="active === 0"
            type="primary"
            @click="addQuestionLibrary"
            >提交
          </el-button>
        </div>
        <el-button
          v-if="active === 2"
          @click="finishCreate"
          type="primary"
          size="small"
          >返回列表
        </el-button>
      </el-row>
    </div>
  </div>
</template>

<script>
import basicInfo from "@/views/operation/components/addContest/basicInfo";
import selectSubject from "@/views/operation/components/addContest/selectSubject";
import selectQuesSet from "@/views/operation/components/addContest/selectQuesSet";
import {
  addQuestionLibrary,
  getContextInfo,
  getQuestionByLibraryId,
  getContestLibraryList,
} from "@/api/research/contest";

export default {
  name: "newAddContest",
  components: {
    basicInfo,
    selectSubject,
    selectQuesSet,
  },
  data() {
    return {
      copyType: false,
      contestId: "",

      sourceList: [], // 来源
      knowledgeList: [], // 知识点
      difficultyList: [], // 难度
      raceType: "刷题题集",
      active: 0, // 步进器
      formData: {}, // form data
    };
  },

  mounted() {
    const query = this.$route.query;
    if (query) {
      this.copyType = query.copyType;
      this.contestId = query.contestId;

      if (!["false", false].includes(this.copyType)) {
        this.getContextInfo();
        this.getQuestionByLibraryId();
      }
    }
  },

  methods: {
    // 获取模板列表
    async getQuestionByLibraryId() {
      const response = await getQuestionByLibraryId({
        contest_id: this.contestId,
        problem_id: "",
        title: "",
      });
      this.$refs.selectSubject.setData(response.body);
      // this.dataList =
    },
    // 查看竞赛详情
    async getContextInfo() {
      const response = await getContextInfo({
        contest_id: this.contestId,
        defunct: "",
      });

      if (response && response.state === "success") {
        const data = response.body;
        this.formData = { ...this.formData, ...data };

        this.formData.langmask = data.langmask.split(",");

        this.formData.applyTime = [];
        this.formData.contestTime = [];

        if (data.endTime === "9999-12-31 23:59:59.0") {
          this.formData.contestTime = [];
        } else {
          this.formData.contestTime.push(data.startTime);
          this.formData.contestTime.push(data.endTime);
        }

        if (data.endEnrollTime === "9999-12-31 23:59:59.0") {
          this.formData.applyTime = [];
        } else {
          this.formData.applyTime.push(data.startEnrollTime);
          this.formData.applyTime.push(data.endEnrollTime);
        }

        this.$refs.basic.formData = this.formData;

        console.log(
          data,
          this.formData,
          data.endEnrollTime.indexOf("9999-12-31 23:59:59.0") === -1
        );
      }
    },
    async validate() {
      const err = [];
      const [valid, data] = await this.$refs["basic"].validate();
      const selectRows = this.$refs["selectQuesSet"].currentRow;
      if (!valid) err.push("请补充完整竞赛信息！");

      if (data.raceType !== "极速竞赛" && !selectRows) {
        err.push("请选择题集！");
      }
      console.log("err", err);
      return err;
    },

    // 新增竞赛
    async addQuestionLibrary() {
      const err = await this.validate();
      if (err.length > 0) {
        this.$message.warning(err[0]);
        return;
      }
      // const selectRows = this.$refs["selectQuesSet"].multipleSelection
      //   .map((item) => item.id)
      //   .join(",");
      const selectRows = this.$refs["selectQuesSet"].currentRow.id;
      const [vaild, data] = await this.$refs["basic"].validate();

      let params = {
        pattern: "竞赛",
        ...data,
        // problem_ids: selectRows ?? [],
        libraryId: selectRows + "",
        startTime: data.contestTime[0],
        endTime: data.contestTime[1],
        startEnrollTime: data.applyTime[0],
        endEnrollTime: data.applyTime[1],
        langmask: data.langmask.join(","),
      };

      delete params.contestTime;
      delete params.applyTime;
      delete params.type;
      delete params.contestTime;

      console.log(params);

      const response = await addQuestionLibrary(params);
      if (response && response.state === "success") {
        window.$msg("新增成功");
        this.active = 2;
      }
    },
    finishCreate() {
      this.$router.push({
        path: "/operation/contestManage",
      });
    },
    changeType(value) {
      this.raceType = value;
      console.log(this.raceType, 7987192398);
    },
    pre() {
      if (this.active-- < 0) this.active = 0;
    },
    async next() {
      const [valid, data] = await this.$refs["basic"].validate();

      if (valid) {
        this.formData = data;
        console.log(data, 999);
        this.active += 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";

.tag {
  display: inline-block;
  color: #fff;
  border-radius: 17px 20px 0px 17px;
  padding: 1px 8px;
  margin-right: 10px;
  margin-bottom: 8px;
}

.tit {
  background-color: white;
  padding: 15px 1px;
  margin: 0 0 10px 0;
  font-size: 18px;
}

.formParent {
  height: 100%;
  background-color: #ffffff;
  width: 1200px;
  padding: 20px;
}

/deep/ .tox .tox-editor-header {
  z-index: 0 !important;
}

.trDascader /deep/ .el-cascader__tags .el-tag {
  max-width: 40%;
}

.trDascader /deep/ .el-cascader__search-input {
  min-width: 20px !important;
  height: 20px !important;
}

/deep/ .el-step__title {
  font-size: 14px !important;
}

// 不存在的id
.seleRow {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  background: white;
  box-sizing: border-box;
  padding-left: 25px;
}

.step-container {
  width: 100%;
  background-color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
</style>
