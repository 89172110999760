<template>
  <el-dialog
    v-if="conditionDialog"
    title="添加过滤条件"
    :visible.sync="conditionDialog"
    append-to-body
    width="900px"
    top="6vh"
    class="dialog"
    :before-close="
      () => {
        $emit('update:conditionDialog', false);
      }
    "
  >
    <div class="flex">
      <el-tabs v-model="activeName" @tab-click="tabsClick" type="card">
        <!-- 已有条件组 -->
        <el-tab-pane
          v-for="(item, index) in conditionArr"
          :key="index"
          :ref="`tabsRef${index}`"
          :label="`条件组${index + 1}`"
          :name="`gruop${index}`"
        >
          <span slot="label" style="padding: 8px">
            <span>
              <span>条件组{{ index + 1 }}</span>
              <el-popconfirm
                title="确认删除当前条件组吗？"
                @confirm="delAlreadyGroup(item, index)"
              >
                <span
                  slot="reference"
                  class="el-icon-close"
                  style="width: 16px"
                  v-if="index != 0"
                ></span>
              </el-popconfirm>
            </span>
          </span>
          <div class="contain" v-if="item.paramList.length">
            <el-checkbox
              v-for="(items, indexs) in item.paramList"
              v-model="items.isShow"
              class="checkBox"
              >{{ items.name }}</el-checkbox
            >
          </div>
          <el-empty v-else description="您已添加所有过滤条件啦"></el-empty>
        </el-tab-pane>
        <!-- 新增条件组 -->
        <!-- <el-tab-pane
        v-if="conditionType == 'group'"
        ref="newCondition"
        :label="`条件组${conditionArr.length + 1}`"
        name="newCondition"
      >
        <div class="contain">
          <el-checkbox
            v-for="(items, indexs) in newConditionObj.paramList"
            v-model="items.isShow"
            class="checkBox"
            >{{ items.name }}</el-checkbox
          >
        </div>
      </el-tab-pane> -->
        <!-- v-if="conditionType == 'group'" -->
        <!-- :label="`条件组${conditionArr.length + newQueryBeanList.length}`"
        :name="`newCondition${newQueryBeanList.length}`"
          :ref="`newCondition${newQueryBeanList.length}`"
      -->
        <el-tab-pane
          v-for="(newItem, newItemIndex) in newQueryBeanList"
          v-if="newQueryBeanList.length"
          :ref="`newCondition${newItemIndex}`"
          :label="`条件组${conditionArr.length + newItemIndex + 1}`"
          :name="`newCondition${newItemIndex}`"
          :key="newItemIndex + 1"
        >
          <span slot="label" style="padding: 8px">
            <span>
              <span>条件组{{ conditionArr.length + newItemIndex + 1 }}</span>
              <el-popconfirm
                title="确认删除当前条件组吗？"
                @confirm="delNewGroup(newItem, newItemIndex)"
              >
                <span
                  slot="reference"
                  class="el-icon-close"
                  style="width: 16px"
                ></span>
              </el-popconfirm>
            </span>
          </span>
          <div class="contain">
            <el-checkbox
              v-for="(items, indexs) in newItem.paramList"
              v-model="items.isShow"
              class="checkBox"
              >{{ items.name }}</el-checkbox
            >
          </div>
        </el-tab-pane>
      </el-tabs>
      <!-- 添加组 -->
      <el-button
        class="addGroupBtn"
        type="primary"
        icon="el-icon-circle-plus-outline"
        :disabled="allGroupLength == 3"
        @click.native="addGroup"
        >添加条件组</el-button
      >
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('update:conditionDialog', false)"
        >取 消</el-button
      >
      <el-button type="primary" @click="confirm">保 存</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    conditionDialog: {
      type: Boolean,
      default: false,
    },
    // 条件组
    queryBeanList: {
      type: Array,
      default: () => [],
    },
    // 类型
    conditionType: {
      type: String,
      default: "condition",
    },
  },
  data() {
    return {
      activeName: "gruop0",
      // 没有被选择搜索条件的数组
      conditionArr: [],
      // 新条件组
      newQueryBeanList: [],
      newConditionObj: {
        paramList: [
          // 题解视频（NotExist：无，Exist：有）
          {
            name: "题解视频",
            condition: "NotExist",
            targetId: "1047",
            isShow: true,
          },
          // 标题
          {
            name: "标题",
            condition: "Like",
            targetId: "1048",
            value: null,
            isShow: false,
          },
          // 关键字id
          {
            name: "ID",
            condition: "Eq",
            targetId: "1053",
            value: null,
            isShow: false,
          },
          // 来源
          {
            name: "来源",
            condition: "In",
            targetId: "1052",
            value: null,
            isShow: false,
          },
          // 知识点
          {
            name: "知识点",
            condition: "In",
            targetId: "1050",
            value: null,
            isShow: false,
          },
          // 难易度
          {
            name: "难易度",
            condition: "In",
            targetId: "1051",
            value: null,
            isShow: false,
          },
          // 状态
          {
            name: "状态",
            condition: "Eq",
            targetId: "1049",
            value: "N",
            isShow: false,
          },
          // 提交记录数
          {
            name: "提交记录数",
            condition: "Eq",
            targetId: "1045",
            value: null,
            rightValue: null,
            isShow: false,
          },
          // 用例数
          {
            name: "用例数",
            condition: "Eq",
            targetId: "1046",
            value: null,
            isShow: false,
          },
        ],
      },
    };
  },
  computed: {
    allGroupLength() {
      return this.conditionArr.length + this.newQueryBeanList.length;
    },
  },
  mounted() {
    // 过滤没有被选择的搜索条件
    // let copyArr = JSON.parse(JSON.stringify(this.queryBeanList));
    this.conditionArr = JSON.parse(JSON.stringify(this.queryBeanList));
    // copyArr.forEach((element) => {
    //   let falseShowArr = element.paramList.filter((e) => !e.isShow);
    //   this.conditionArr.push({
    //     paramList: falseShowArr,
    //   });
    // });
    // 新增条件组
    if (this.conditionType == "group") {
      // this.newQueryBeanList.push(this.newConditionObj);
      // this.activeName = `newCondition${this.newQueryBeanList.length}`;
      this.addGroup();
    }
  },
  methods: {
    tabsClick() {},
    // 添加条件组
    addGroup() {
      let newGroup = {};
      Object.assign(newGroup, this.newConditionObj);
      let arr = JSON.parse(JSON.stringify(this.newQueryBeanList));
      for (let i = 0; i < newGroup.paramList.length; i++) {
        newGroup.paramList[i].isShow = false;
      }
      this.newQueryBeanList = [...arr, newGroup];
      // this.newQueryBeanList.push(newGroup);
      this.activeName = `newCondition${this.newQueryBeanList.length - 1}`;
    },
    // 对已有的条件组进行删除
    delAlreadyGroup(item, index) {
      this.$emit("delAlreadyGroup", index);
      this.activeName = "gruop0";
      this.conditionArr.splice(index, 1);
    },
    // 对新增的条件组进行删除
    delNewGroup(newItemIndex) {
      this.newQueryBeanList.splice(newItemIndex, 1);
      this.activeName = "gruop0";
    },
    // 保存
    confirm() {
      let isShowArr = this.conditionArr[0].paramList.filter((item) => {
        return item.isShow;
      });
      if (!isShowArr.length) {
        return $msg("请勾选搜索条件！");
      }
      this.$emit("confirmCondition", this.conditionArr);
      // 添加条件组
      // if (this.conditionType == "group") {
      if (this.newQueryBeanList.length) {
        // let flag = this.newConditionObj.paramList.some((item) => {
        //   return item.isShow;
        // });
        for (let i = 0; i < this.newQueryBeanList.length; i++) {
          for (let k = 0; k < this.newQueryBeanList[i].paramList.length; k++) {
            let flag = this.newQueryBeanList[i].paramList.some((item) => {
              return item.isShow;
            });
            if (!flag) {
              $msg(
                `新增条件组${this.conditionArr.length + i + 1}未增加新的字段!`,
                2
              );
              return;
              break;
            }
          }
        }
        // if (!flag) {
        //   $msg("新增条件组未增加新的字段，自动取消!");
        //   this.$emit("update:conditionDialog", false);
        //   return;
        // }
        // this.$emit("confirmConditionGroup", this.newConditionObj);
        this.$emit("confirmConditionGroup", this.newQueryBeanList);
        this.$emit("update:conditionDialog", false);
      }
      this.$emit("update:conditionDialog", false);
    },
  },
};
</script>
<style>
.flex {
  display: flex;
}
</style>
<style scoped lang="scss">
.contain {
  padding: 30px;
  background: rgb(242, 245, 247);
  .checkBox {
    margin-bottom: 15px;
  }
}
.dialog {
  .addGroupBtn {
    position: absolute;
    right: 20px;
  }
}
</style>
