var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "table" }, [
    _c(
      "div",
      { staticClass: "step-container" },
      [
        _c(
          "el-steps",
          {
            staticStyle: { "margin-bottom": "15px", width: "500px" },
            attrs: { active: _vm.active, "finish-status": "success" },
          },
          [
            _c("el-step", { attrs: { title: "竞赛设置" } }),
            _vm.raceType !== "极速竞赛"
              ? _c("el-step", { attrs: { title: "选择题集模板" } })
              : _vm._e(),
            _c("el-step", { attrs: { title: "完成" } }),
          ],
          1
        ),
        _c("basicInfo", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active === 0,
              expression: "active === 0",
            },
          ],
          ref: "basic",
          on: { changeType: _vm.changeType },
        }),
        _c("selectQuesSet", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active === 1,
              expression: "active === 1",
            },
          ],
          ref: "selectQuesSet",
        }),
        _vm.active === 2
          ? _c("el-row", [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center",
                    "align-items": "center",
                    "flex-direction": "column",
                    "margin-top": "40px",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-circle-check",
                    staticStyle: { "font-size": "50px", color: "#0ca51a" },
                  }),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "margin-top": "20px",
                        "font-weight": "bold",
                        "font-size": "25px",
                      },
                    },
                    [_vm._v("创建成功")]
                  ),
                ]
              ),
            ])
          : _vm._e(),
        _c(
          "el-row",
          {
            staticClass: "footerparent",
            attrs: { slot: "footer" },
            slot: "footer",
          },
          [
            _vm.raceType !== "极速竞赛"
              ? _c(
                  "div",
                  [
                    _vm.active === 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.pre },
                          },
                          [_vm._v("上一步 ")]
                        )
                      : _vm._e(),
                    _vm.active === 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.next },
                          },
                          [_vm._v("下一步 ")]
                        )
                      : _vm._e(),
                    _vm.active === 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.addQuestionLibrary },
                          },
                          [_vm._v("提交 ")]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _vm.active === 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.addQuestionLibrary },
                          },
                          [_vm._v("提交 ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
            _vm.active === 2
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.finishCreate },
                  },
                  [_vm._v("返回列表 ")]
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }